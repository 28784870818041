















































import { Component, Vue, Prop } from 'vue-property-decorator'
import CloudUploadIcon from '@/app/ui/assets/cloud_upload_icon.vue'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'
import AttachIcon from '@/app/ui/assets/attach_icon.vue'

@Component({
  components: {
    CloudUploadIcon,
    AttachIcon
  },
})
export default class FileUpload extends Vue {
  @Prop({ type: String, default: '' }) private fieldLabel?: string
  @Prop({ type: Boolean, default: false }) private required?: boolean
  @Prop({ type: String }) private accept?: string
  @Prop({ type: String }) private format?: string
  @Prop({ type: String, default: 'Upload Image' }) private text!: string
  @Prop({ type: String, default: '' }) private filename!: string
  @Prop({ type: String, default: '' }) private status!: EnumStatusUpload

  dragOver = false
  error = ''
  EnumStatusUpload = EnumStatusUpload

  private onInputFileChange(files: File): void {
    try {
      if (files) {
        const splitFileName = files.name.split('.')
        const formatFiles = splitFileName[splitFileName.length - 1]
        if (
          this.format &&
          !this.format.split(',').includes(`.${formatFiles}`)
        ) {
          throw new Error(`File Type must be in ${this.format || this.accept}`)
        }

        this.$emit('on-input', files)
      }
    } catch (error) {
      const err = <Error>error
      // Show error alert
      this.$notify({
        title: 'Input File Failed',
        text: err.message,
        type: 'error',
        duration: 5000,
      })
    }
  }

  private drop({ dataTransfer }: { dataTransfer: DataTransfer }): void {
    this.dragOver = false
    this.onInputFileChange(dataTransfer?.files[0])
  }

  private dragover(): void {
    this.dragOver = true
  }

  private dragleave(): void {
    this.dragOver = false
  }
}
