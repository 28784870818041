export function setStylingErrorMessage(): void {
  const sectionBulkUpload = document.querySelector('.section-bulk-upload') as HTMLDivElement
  if (sectionBulkUpload) {
    const errorBulkUpload = document.querySelector('.error-bulk-upload') as HTMLDivElement
    const listError = document.querySelector('.list-error') as HTMLDivElement
    if (errorBulkUpload) {
      errorBulkUpload.style.maxHeight = `${sectionBulkUpload.clientHeight}px`
      listError.style.maxHeight = `${sectionBulkUpload.clientHeight - 180}px`
    }
  } 
}
