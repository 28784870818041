


































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import FileUpload from './components/FileUpload/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import IllustrationUploadBulk from '@/app/ui/assets/illustration_upload_bulk_product.vue'
import IconWarningTrianglesLine from '@/app/ui/assets/icon_warning_triangles_line.vue'
import controller from '@/app/ui/controllers/ProductController'
import { setStylingErrorMessage } from '../Product/utils'
import { HistoryLogBulkProduct, HistoryLogBulkProducts } from '@/domain/entities/Product'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Button,
    Badge,
    FileUpload,
    DataTable,
    EmptyState,
    PaginationNav,
    IllustrationUploadBulk,
    IconWarningTrianglesLine
  },
})
export default class ProductPage extends Vue {
  controller = controller
  parameters = {
    page: 1,
    perPage: 10
  }
  headers = [
    'Date and Time',
    'Username',
    'File Name',
    'Status',
  ]
  dataHistoryLog = Array<string[]>([])
  currentDataHistoryLog = new HistoryLogBulkProduct()
  filename = ''
  errorValidate = {
    title: '',
    meta: <string[]>([])
  }

  created(): void {
    this.fetchHistoryLog()
  }

  private fetchHistoryLog(): void {
    controller.getHistoryLogBulkProduct(this.parameters)
  }

  private getType(status: string): string | undefined {
    if (status) {
      if (status.toLowerCase() === 'complete') {
        return 'success'
      } else if (status.toLowerCase() === 'processing') {
        return 'warning'
      } else {
        return 'error-1'
      }
    }
  }

  private onInputFileBulkUplaod(file: File): void {
    this.filename = file.name
    controller.uploadProductBulk({ file, params: this.parameters })
  }

  private dateTimeFormat(date: string | null): string {
    if (!date) return '-'
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(date, 'DD MMMM YYYY, HH:mm Z')
    )
  }

  @Watch('controller.historyLogBulkProduct')
  setErrorMessage(historyLog: HistoryLogBulkProducts): void {
    if (historyLog && historyLog.data) {
      // Set Current Data History Log
      if (historyLog.pagination) {
        const currentPage = historyLog.pagination.page
        if (currentPage === 1) {
          const currentHistoryLog = historyLog.data[0]
          const statusLog = historyLog.data[0].status

          this.currentDataHistoryLog = new HistoryLogBulkProduct(
            currentHistoryLog.id,
            currentHistoryLog.username,
            currentHistoryLog.filename,
            currentHistoryLog.errorTitle,
            currentHistoryLog.meta,
            currentHistoryLog.status,
            currentHistoryLog.createdAt
          )

          // Set Status Log
          if (statusLog === 'Processing') {
            this.filename = <string>historyLog.data[0].filename
          }

          // Set Validate Error Message
          this.errorValidate = {
            title: <string>currentHistoryLog.errorTitle,
            meta: <string[]>currentHistoryLog.meta
          }
        }
      }

      // Set Table History log
      const result = <Array<string[]>>historyLog.data.map(item => {
        const row = [
          this.dateTimeFormat(<string>item.createdAt),
          item.username,
          item.filename,
          item.status
        ]
        return row
      })
      this.dataHistoryLog = result
    }
  }

  updated(): void {
    setStylingErrorMessage()
  }
}
